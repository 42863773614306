import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Engineer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="データ分析スタッフ 募集要項｜Recruit｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-recruitPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Recruit</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/recruit#message" className=''>
                社長からのメッセージ
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#RecruitmentType" className=''>
                募集職種
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#WorkingWelfare" className=''>
                働き方・福利厚生
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#FAQ" className=''>
                採用FAQ
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="RecruitmentType" className="mb-20">
              <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-5 py-3 ">
              データ分析スタッフ
              </h2>
              <h3 className="text-xl">募集要項</h3>
              <table className="my-4 border border-key-sand">
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">雇用形態</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  正社員
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">勤務地</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  東京都千代田区神田
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">最寄駅</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  JR「神田駅」より徒歩6分<br />
                  JR中央線・総武線「御茶ノ水駅」より徒歩7分<br />
                  東京メトロ千代田線「新御茶ノ水駅」より徒歩2分<br />
                  都営新宿線「小川町」より徒歩2分<br />
                  東京メトロ丸の内線「淡路町駅」より徒歩2分
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell">業務内容</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ◆各種医療データの分析、調査、コンサルティング業務を行っている会社内での常駐データ分析スタッフ<br />
                  <br />
                  医療分野におけるリサーチのオーダーに対して、データベースを用いたデータ抽出・分析、レポート作成を行っていただくお仕事です。<br />
                  医療機関では、どの病気に対して、どの薬が処方されているのか、どのような治療効果があったのか、等の実臨床データを、SQLを用いて抽出・分析します。<br />
                  抽出・分析したデータ結果は製薬会社／研究機関にフィードバックされ、最終的に新薬の開発などに活用されています。<br />
                  <br />
                  【基本的な業務の流れ】<br />
                  ▽当社の取引先である、各種医療データの分析、調査、コンサルティング業務を行っている会社から、製薬会社の依頼に基づいたデータ抽出・分析のオーダー。<br />
                  ▽オーダー内容について、調査会社の担当者とすり合わせ。<br />
                  ▽医療ビッグデータの中から、SQLを使用して必要としているデータを抽出。<br />
                  ○調査会社の担当者と、お互いの分析結果を照合。認識にズレがなければ、結果を納品します。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">仕事のやりがい</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ・製薬業界への貢献が大きく、抽出・分析したデータは、新薬の開発などに活用されていきます。そんな、社会的に意義のある役割を担います。<br />
                  ・難しい案件をこなした後や繁忙期を乗り切った後には大きな達成感が得られます。<br />
                  ・常駐しているので、調査会社の担当者様から直接感謝のお言葉をいただけることがあります。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">キャリアアップ</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ・はじめのうちは弊社スタッフによる丁寧なOJT研修がありますので、データ分析未経験でも問題ありません。<br />
                  ・データ分析ができるようになります。<br />
                  ・医療、製薬業界の知識が身につきます。<br />
                  ・社員のスキルアップを積極的に応援しています。技術系の書籍購入補助、資格取得・セミナー受講の支援などを行っています。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">研修</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  【入社後研修の流れ】<br />
                  ※あくまで目安のため、ご本人のスキルや習得スピードに合わせてスケジュール設定をいたします。<br />
                  ■1～2日目：業務説明後、テストデータを使用して抽出条件の設定などを試し、データの扱いについて大枠を理解していただきます。<br />
                  ■～1ヵ月目：過去案件などを用いて、データ抽出・分析を少しずつ習得していただきます。<br />
                  ※専門用語などは多くありません。簡単な実案件がタイミングよくあれば取り組んでいただくこともありますが、基本的にはテストデータでの研修となります。<br />
                  ■～2ヵ月目：過去案件でのデータ抽出と並行しながら、少しずつOJTにて実案件に取り組んでいただきます。<br />
                  初めのうちは、他スタッフが同じ案件を操作して答え合わせをしながら実施するため、安心して進めることができます。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">応募資格</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  【必須条件】<br />
                  ◆社会人経験3年以上<br />
                  ◆SQLの知識がある方（独学可）<br />
                  ◆Excel操作スキル・実務経験(関数、データ集計など) <br />
                  ◆数字を扱うことに抵抗が無く、コツコツした作業ができる方<br />
                  ◆社内外問わず、社交性／コミュニケーション能力のある方<br />
                  　<br />
                  【歓迎条件】<br />
                  ◆SQLの実務経験<br />
                  ◆医療・健康分野に興味・関心がある方<br />
                  ◆データベースに関する知識がある方<br />
                  ◆ビッグデータの分析経験のある方<br />
                  ◆調査会社でのレポート作成／データ分析の実務経験がある方<br />
                  ◆考えることが好きで論理的思考力を得意とする方<br />
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">学歴</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  学歴不問
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">勤務時間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  9：00～18：00（所定労働時間 8時間、休憩1時間）
                  
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">給与</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  330～500万円（経験・能力に応じて）<br />
                  ※月給：220,000円～333,400円
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">残業</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  平均月17時間程度、残業手当全額別途支給
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">賞与</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  年2回（6,12月、計3か月分）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">昇給</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  年1回
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">試用期間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ６カ月
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">通勤手当</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  通勤交通費支給（上限5万円／月）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">社会保険</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  社会保険完備（雇用、労災、健康、厚生年金）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">喫煙場所</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  敷地内禁煙（屋内1階に喫煙室あり）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">福利厚生</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  定期健康診断実施、書籍購入補助、資格取得・セミナー受講の支援あり、インフルエンザ予防接種費用負担、国内/海外社員旅行
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">休日休暇</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  完全週休2日制（土、日、祝）、年末年始・夏季休暇、有給休暇、慶弔休暇、出産・育児・介護休暇
                  </td>
                </tr>

                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">選考プロセス</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ① 書類選考：書類選考<br />
                  ② 一次選考：面接（役員）<br />
                  ③ 二次選考：適性検査＋SQL筆記テスト<br />
                  ④ 三次選考：面接（役員＋現場スタッフ）<br />
                  ※選考状況によって、面接回数・適性検査の順序が変更となる場合があります。<br />
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">選考期間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  書類選考、面接ともに1週間以内
                  </td>
                </tr>
              </table>

              <Link to="/requirement/entry" class="my-10 flex justify-center bg-[#8CBCB5] px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand shadow">
                <span className="text-2xl text-white font-semibold">応募する</span>
              </Link>


            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Engineer

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

